@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@400;700&family=DM+Serif+Display:ital@0;1&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 .App {
  text-align: center;
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Rajdhani", sans-serif;
  letter-spacing: 1.5px;
  /* overflow-x: hidden;
  overflow-y: scroll; */
  
}

.submit {
  background-color: rgb(44, 177, 29);
}

body::-webkit-scrollbar {
  display: none;
}
.App {
  background-image: linear-gradient(to right, green, yellow);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
}

.navbar-expand {
  background-image: linear-gradient(to right, green, yellow);
  background-size: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* App */
.nav {
  display: flex;
  justify-content: space-between;
  text-shadow: 1px 1px grey;
  margin-right: 4rem;
  margin-bottom: 1.3rem;
  margin-top: 0.5rem;
}

.logo img {
  width: 12rem;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  background-color: white;
  box-shadow: 3px 3px 18px black;
}

nav .logo {
  display: block;
  float: left;
  margin-left: 3rem;
  margin-top: 1rem;
}

.navli {
  margin: 0.6rem;
  grid-gap: 100px;
  gap: 100px;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}
.links {
  display: flex;
  justify-content: flex-end;
}

.navlihidden {
  display: none;
}

/* Title.js */
.title {
  font-family: "Rajdhani", sans-serif;
  text-shadow: 1px 1px grey;
  letter-spacing: 1.1rem;
  width: 100%;
  font-weight: bold;
  margin-left: 5rem;
}
.subtitle {
  font-family: "Rajdhani", sans-serif;
  text-shadow: 1px 1px grey;
  font-size: 1.6rem;
  font-weight: bold;
  overflow: hidden;
  border-right: 5px black;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: subtitle 4.5s steps(360, end), blink-caret 1.75s step-end infinite;
  animation-iteration-count: infinite;
  margin-left: 6.5rem;
  margin-bottom: 1.5rem;
}

@keyframes subtitle {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.line {
  border-top: 1px solid rgba(116, 219, 5, 0.594);
  margin-top: -1rem;
  margin-bottom: 1rem;
  width: 100%;
}

/* Body */
.body {
  display: flex;
  height: 10rem;
  margin-top: 50px;
  margin-bottom: 2px;
  display: block;
  text-align: center;
  text-shadow: 1px 1px grey;
  font-weight: bold;
}
.bodyInv {
  visibility: hidden;
}

.body1 {
  display: block;
  margin-top: 0.5rem;
  font-size: 3rem;
  text-align: center;
  text-shadow: 1px 1px grey;
  font-weight: bold;
}

/* Products */
.products {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: auto;
  display: flex;
  justify-content: center;
  grid-gap: 25px;
  gap: 25px;
  background-image: url(/static/media/leafybackground2.f9604ea6.png);
  background-size: 100% 100%;
  background-position: fixed;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 80%;
}

.card {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  box-shadow: 3px 13px 13px green;
}

@media screen and (max-width: 1000px) {
  .subtitle {
    font-size: 0.9rem;
  }

  .products {
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* width: 50%; */
    margin-left: auto;
    margin-right: auto;
  }
  .card {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .body {
    margin-bottom: 3rem;
  }

  .aboutus .info3 .info4 {
    margin-left: auto;
    margin-right: auto;
  }

  .reviews h4 {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .therevs {
    margin-left: auto;
    margin-right: auto;
  }

  .App {
    background-image: linear-gradient(to right, green, yellow);
  }
}

.card img {
  width: 22.55rem;
}

.card-img-top {
  border-radius: 10px;
}

.card-title {
  color: white;
  text-shadow: 1px 1px grey;
  font-weight: bold;
  background-color: rgb(131, 193, 73);
}

.fruitpic {
  height: 100%;
  width: 100%;
  background-color: rgb(131, 193, 73);
}

.card-body {
  background-color: rgb(131, 193, 73);
  border-radius: 10px;
}

.card-text {
  font-size: 2rem;
  text-shadow: 1px 1px grey;
  font-weight: bold;
  background-color: rgb(131, 193, 73);
}

.btn-primary {
  background-color: rgb(44, 177, 29);
  border: 1px solid rgb(131, 193, 73);
  text-shadow: 1px 1px grey;
  font-weight: bold;
}

/* Info */
.pics {
  height: 200px;
  width: 400px;
}
.info {
  padding: 0.5rem;
}

.info p {
  text-shadow: 1px 1px grey;
  font-weight: bold;
  padding: 0.5rem;
}

.info1 {
  display: block;
  padding: 0.5rem;
}

.info1pic {
  float: left;
}

.info2 {
  display: block;
}

.info2pic {
  float: left;
}

.info3 {
  display: block;
}

.info3pic {
  float: right;
  margin-bottom: 25px;
  margin: 0.3rem;
}

/* Contact */
.contact-form {
  height: 100vh;
  margin-top: 5rem;
}

.form-horizontal {
  grid-gap: 1rem;
  gap: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  border: 2px solid white;
  width: 500px;
  padding-top: 4rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.submitbutton input {
  margin-left: 47%;
  margin-bottom: 2rem;
}

.label {
  text-shadow: 1px 1px grey;
  font-weight: bold;
  color: white;
  font-size: 1.3rem;
}

input[type="text"] {
  border: 1px solid white;
  border-radius: 5px;
  width: 350px;
  height: max-width;
  font-weight: bold;
  color: rgb(5, 5, 5);
  padding: 1rem;
}
input[type="email"] {
  border: 1px solid white;
  border-radius: 5px;
  width: 350px;
  height: 100%;
  font-weight: bold;
  color: rgb(5, 5, 5);
  padding: 1rem;
}

textarea {
  border: 1px solid white;
  border-radius: 5px;
  width: 350px;
  height: 150px;
  overflow: hidden;
  font-weight: bold;
  color: rgb(5, 5, 5);
}

.submit {
  border: 1px solid white;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  padding: 0.5rem;
  text-align: center;
  width: 5rem;
}

.submit:hover {
  background-color: blue;
}

/* About Us */
.aboutus {
  text-align: center;
  width: 100%;
}

img {
  height: 15rem;
  width: 15rem;
  margin: 0.2rem;
  border-radius: 5px;
}

p {
  text-shadow: 1px 1px grey;
  font-weight: bold;
  color: white;
  font-size: 1.3rem;
}

/* .info3 .p1 {
  animation: slide1 0.3s ease-in-out;
  animation-duration: 3s;
}

@keyframes slide1 {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0%);
  }
}

.info4 .p2 {
  animation: slide2 0.3s ease-in-out;
  animation-duration: 3s;
}
@keyframes slide2 {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0%);
  }
} */

.reviews {
  text-align: center;
}

.reviews a {
  text-decoration: none;
  -webkit-text-decoration: "none";
          text-decoration: "none";
  font-weight: bold;
  color: white;
  text-align: center;
}

.therevs {
  width: 80%;
  margin: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

/* Discount */
.discount {
  position: fixed;
  top: 50%;
  right: 5%;
  border: 0.5px solid white;
  border-radius: 5px;
  animation: fadein 5s ease-out forwards;
  opacity: 0.7;
}

.discountsubmit {
  margin-top: 1rem;
}

.discountform {
  height: 100vh;
  margin-top: 3rem;
}

@keyframes fadein {
  0% {
    transform: translateY(-750%);
  }
  50% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}

.discount-notvisible {
  display: none;
}

button {
  text-shadow: 1px 1px grey;
  font-weight: bold;
  color: white;
  font-size: 1.3rem;
  border: 0.5px solid white;
  border-radius: 5px;
  padding: 0.5rem;
}

.button {
  background-color: rgb(44, 177, 29);
}

.submit {
  margin-bottom: 1rem;
}

.discountcode {
  text-align: center;
  text-shadow: 1px 1px grey;
  font-weight: bold;
  color: white;
  margin-top: 2rem;
  display: none;
}

.discountcodeshow {
  text-align: center;
  -webkit-text-decoration: "none";
          text-decoration: "none";
  font-weight: bold;
  color: white;
  margin-top: 2rem;
}

/* Review2 */
.review-img {
  width: 38rem;
  height: 18rem;
  border-radius: 5px;
  box-shadow: 3px 3px 18px black;
}

/* Footer */
.footer {
  margin-bottom: -5rem;
}

.footer ul {
  display: flex;
  justify-content: center;
  list-style: none;
}
.footer a {
  text-decoration: none;
}

.footer li {
  text-decoration: none;
  margin: 4.5rem;
  text-shadow: 1px 1px grey;
  font-weight: bold;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* Policy */
.policy p {
  margin: 2rem;
  text-align: center;
}

.policy span {
  text-decoration: underline;
}

/* RecentPurch */
.recentmain {
  display: flex;
  justify-content: space-between;
  width: 285px;
  height: 145px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3.5px;
  margin-left: 1.5px;
  border: 1px solid grey;
  padding: 3rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 20px;
  left: 0;
  border-radius: 5px;
  line-height: 0.8rem;

  opacity: 1;
  animation: fadeInOpacity 5s linear forwards;
  animation-iteration-count: infinite;
  -webkit-animation: fadeInOpacity 4s linear forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.recentmaintwo {
  display: flex;
  justify-content: space-between;
  width: 285px;
  height: 145px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3.5px;
  margin-left: 1.5px;
  border: 1px solid grey;
  padding: 3rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 20px;
  left: 0;
  border-radius: 5px;
  line-height: 0.7rem;

  opacity: 1;
  animation: fadeOutOpacity 5s linear forwards;
  animation-iteration-count: infinite;
  -webkit-animation: fadeOutOpacity 4s linear forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
}
@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.recentmain-hidden {
  visibility: hidden;
}

.recentmain div {
  background: transparent;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  margin-left: 60px;
  margin-top: -15px;
}

.recentmain img {
  position: fixed;
  width: 100px;
  height: 95px;
  border-radius: 5px;
  position: fixed;
  bottom: 45px;
  left: 0;
  margin-left: 0.5rem;
}

.recentmain span {
  background: transparent;
  color: rgb(5, 5, 5);
  font-size: 0.7rem;
}

.recentmaintwo div {
  background: transparent;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  margin-left: 60px;
  margin-top: -15px;
}

.recentmaintwo img {
  position: fixed;
  width: 100px;
  height: 95px;
  border-radius: 5px;
  position: fixed;
  bottom: 45px;
  left: 0;
  margin-left: 0.5rem;
}

.recentmaintwo span {
  background: transparent;
  color: green;
  font-size: 0.7rem;
}

/* _________________________________________________- */
.bg {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/JunePlums-MyRedlandsFarm.85236d65.png);
  background-position: bottom;
  background-size: cover;
  margin-top: -15px;
}

.links1 {
  display: flex;
  flex-direction: row;
}

.list {
  color: rgb(6, 142, 6);
  list-style: none;
  margin-left: 3rem;
  font-size: 1.5rem;
  text-shadow: 1px 2px black;
  font-weight: bolder;
}

.logo2 {
  text-align: end;
  width: 100%;
  height: auto;
  border: 2px solid black;
  image-rendering: optimizeQuality;
}

.topintro {
  text-align: center;
  font-size: 2.5rem;
  color: rgb(6, 142, 6);
  text-shadow: 1px 2px white;
  font-weight: bolder;
}

.orderbuttons {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.ship {
  color: black;
  font-family: "DM+Serif+Display";
  text-shadow: 2px 0.5px grey;
  font-weight: bolder;
  text-align: center;
  font-size: 2.5rem;
}

.button1 {
  margin-right: 5px;
  background-color: rgb(6, 142, 6);
  transition: all 0.2s ease-in-out;
}

.button1:hover {
  transform: scale(1.1);
  /* transform: translateX(12%); */
}

.button2 {
  background-color: rgb(6, 142, 6);
  transition: all 0.2s ease-in-out;
}

.button1 > a {
  text-shadow: 1px 0.5px black;
  font-weight: bolder;
  font-size: 1.5rem;
  color: white;
}

.button2 > a {
  text-shadow: 1px 0.5px black;
  font-weight: bolder;
  font-size: 1.5rem;
  color: white;
}

.button2:hover {
  transform: scale(1.1);
}

.centerpiece {
  text-align: center;
}

.center1 {
  font-size: 5.5rem;
  font-family: "DM+Serif+Display";
  color: rgb(109, 230, 3);
  text-shadow: 2px 2px black;
  letter-spacing: 7px;
}

.center2 {
  font-size: 2.5rem;
  font-family: "DM+Serif+Display";
  color: white;
  text-shadow: 1px 2px black;
  letter-spacing: 7px;
}

.center3 {
  font-size: 4.5rem;
  font-family: "DM+Serif+Display";
  color: rgb(109, 230, 3);
  text-shadow: 2px 2px black;
  letter-spacing: 7px;
}

.center4 {
  font-size: 5.5rem;
  font-family: "DM+Serif+Display";
  color: rgb(109, 230, 3);
  text-shadow: 2px 2px black;
  letter-spacing: 7px;
}

.section2 {
  background-color: rgb(3, 3, 3) !important;
  display: flex;
  justify-content: space-between;
  /* animation: appear 5s linear; */
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
}

@media (max-width: 1500px) {
  .section2 {
    display: block;
    text-align: center;
    animation: appear 3s linear; 
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 30%;
  } 

  .section2aimg {
    height: auto;
    width: 95% !important;
  } 

  .section2asub {
    margin: 0;
    width: 100%;
    text-align: center;
  } 

  .shape1 {
    display: none; 
  }  

  .shape2 {
    display: none; 
  }  
}  

.section2asub {
  color: rgb(6, 142, 6);
  text-shadow: 1px 0px white;
  font-weight: bolder;
  margin-left: 10px;
  margin-right: 10px;
  letter-spacing: 2px;
  font-size: 1.5rem;
  align-self: center;
  z-index: 1;
}

.section2aimg {
  height: auto;
  width: 45rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.orderbuttons2 {
  text-align: center;
  background-color: rgb(0, 0, 0);
  /* animation: appear 5s linear; */
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
}

.shape1 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: rgb(74, 246, 74);
  border-radius: 50%;
  top: 100;
  right: -100px;
  transform: translateY(-50%);
  transform: translateX(10%);
  z-index: 1;
}

.shape2 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: rgb(74, 246, 74);
  border-radius: 50%;
  bottom: 50;
  left: -100px;
  transform: translateY(-100%);
  transform: translateX(-10%);
  z-index: 0.1;
}

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.recipe {
  background-color: white;
  /* animation: appear 5s linear; */
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 20%;
}

.main-sec {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.rectitle {
  text-align: center;
  font-size: 5.5rem;
  color: rgb(6, 142, 6);
  text-shadow: 1px 1.5px black;
  font-weight: bolder;
  font-family: "DM+Serif+Display";
}

.reccards {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.card {
  transition: transform 0.3s ease-in-out;
}

.recinstruction1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 3s, opacity 0.5s linear;
  z-index: 10;
}

.reccards:hover .recinstruction1 {
  visibility: visible;
  transform: scale(1.1);
  opacity: 1;
}

.juice {
  margin-left: 12px;
  height: 16rem;
}

